import React, { useContext } from "react";
import "./Number.scss";
import {
  FaCity,
  FaGlobeAmericas,
  FaUniversity,
  FaUserGraduate,
} from "react-icons/fa";
import CountUp from "react-countup";
import { PageContext } from "../../contexts/PageContext";

export default function Number() {
  const { country, city, university, course } = useContext(PageContext);
  return (
    <div className="number-section">
      <div className="text">
        Guiding lakhs of Students and Parents to find right university.
      </div>
      <div className="number">
        <div className="option">
          <div className="icon">
            <FaGlobeAmericas />
          </div>
          <div className="details">
            <div className="count">
              <CountUp end={country.length || 50} duration={10} />
              <span>+</span>
            </div>
            <div className="icon-title">Country</div>
          </div>
        </div>
        <div className="option">
          <div className="icon">
            <FaCity />
          </div>
          <div className="details">
            <div className="count">
              <CountUp end={city.length || 20} duration={10} />
              <span>+</span>
            </div>
            <div className="icon-title">City</div>
          </div>
        </div>
        <div className="option">
          <div className="icon">
            <FaUniversity />
          </div>
          <div className="details">
            <div className="count">
              <CountUp end={university.length || 10} duration={10} />
              <span>+</span>
            </div>
            <div className="icon-title">University</div>
          </div>
        </div>
        <div className="option">
          <div className="icon">
            <FaUserGraduate />
          </div>
          <div className="details">
            <div className="count">
              <CountUp end={course.length || 30} duration={10} />
              <span>+</span>
            </div>
            <div className="icon-title">Course</div>
          </div>
        </div>
      </div>
    </div>
  );
}
