import React, { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getAllData, storeData } from "../api/apiService";
import createPayload from "../utils/createPayload";

export const PageContext = createContext(null);

// Create the Provider Component
export const PageContextProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState("howitworks");
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [university, setUniversity] = useState([]);
  const [course, setCourse] = useState([]);
  const [district, setDistrict] = useState([]);
  const [consultancyOptions, setConsultancyOptions] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);

  const [sortlistedConsultancyIds, setSortlistedConsultancyIds] = useState([]);

  const [loading, setLoading] = useState(true);

  // Simulate loading state
  useEffect(() => {
    const timer = setTimeout(
      () => {
        setLoading(false);
      },
      country.length > 0 ? 1000 : 3000
    );
    return () => clearTimeout(timer);
  }, [country]);

  // Fetch dropdown options
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [
          countryData,
          cityData,
          universityData,
          courseData,
          districtData,
        ] = await Promise.all([
          getAllData("admin/getcountries"),
          getAllData("admin/getcities"),
          getAllData("admin/getuniversities"),
          getAllData("admin/getcourses"),
          getAllData("user/getdistricts"),
        ]);

        setCountry(countryData.data);
        setCity(cityData.data);
        setUniversity(universityData.data);
        setCourse(courseData.data);
        setDistrict(districtData.data.data);
      } catch (error) {
        toast.error("Error getting dropdown options");
      }
    };

    fetchOptions();
  }, []);

  // Handle filter button click
  const handleFilterButton = async (selectedOptions, selectedDistricts) => {
    setCurrentPage("browse");
    setSelectedUniversity(selectedOptions.selectedUniversities);
    setSelectedCourse(selectedOptions.selectedCourses);
    setSelectedCountry(selectedOptions.selectedCountries);
    setSelectedCity(selectedOptions.selectedCities);
    setSelectedDistrict(selectedDistricts);

    setConsultancyOptions([]);

    try {
      const payload = createPayload(
        1,
        100,
        selectedOptions.selectedCountries,
        selectedOptions.selectedUniversities,
        selectedOptions.selectedCourses,
        selectedOptions.selectedCities,
        selectedDistricts
      );
      const response = await storeData("admin/getconsultancies", payload);
      if (response.status === 200) {
        setConsultancyOptions(response.data.data);
      } else {
        toast.error("No Consultancies Found. Please Try Other Options.");
        return [];
      }
    } catch (err) {
      toast.error("Error Fetching Data");
    }
  };

  // Define context values
  const contextValues = {
    loading,
    setLoading,
    currentPage,
    setCurrentPage,
    country,
    setCountry,
    city,
    setCity,
    university,
    setUniversity,
    course,
    setCourse,
    district,
    setDistrict,
    selectedUniversity,
    setSelectedUniversity,
    selectedCourse,
    setSelectedCourse,
    selectedCountry,
    setSelectedCountry,
    selectedCity,
    setSelectedCity,
    selectedDistrict,
    setSelectedDistrict,
    consultancyOptions,
    setConsultancyOptions,
    sortlistedConsultancyIds,
    setSortlistedConsultancyIds,
    handleFilterButton,
    isDropdownVisible,
    setIsDropdownVisible,
  };

  return (
    <PageContext.Provider value={contextValues}>
      {children}
    </PageContext.Provider>
  );
};
