import React, { useContext, useEffect, useState } from "react";
import "./Browse.scss";
import ConsultancyCard from "../../components/conCard/ConsultancyCard";
import ConsultancyDetail from "../../components/conDetail/ConsultancyDetail";
import Sortlist from "../../components/sortPop/Sortlist";
import SubmitForm from "../../components/formPop/SubmitForm";
import { getDataById } from "../../api/apiService";
import { toast } from "react-toastify";
import SkeletonConsultancyCard from "../../components/conCard/SkeletonConsultancyCard";
import SkeletonConsultancyDetail from "../../components/conDetail/SkeletonConsultancyDetail";
import { PageContext } from "../../contexts/PageContext";

export default function Browse() {
  const {
    sortlistedConsultancyIds,
    setSortlistedConsultancyIds,
    currentPage,
    consultancyOptions,
    selectedUniversity,
    selectedCourse,
    selectedCountry,
    selectedCity,
  } = useContext(PageContext);

  const [loadingConsultancy, setLoadingConsultancy] = useState(false);
  const [selectedConsultancy, setSelectedConsultancy] = useState(null);

  const [showSortlistPopup, setShowSortlistPopup] = useState(false);

  useEffect(() => {
    handleSelectConsultancy();
    // if (
    //   currentPage === "browse" &&
    //   selectedCity.length < 1 &&
    //   selectedCountry.length < 1 &&
    //   selectedUniversity.length < 1 &&
    //   selectedCourse.length < 1
    // ) {
    //   toast.warning("Please Select Filters For Searching Consultancies");
    // }
  }, [currentPage]);

  // Function to toggle the shortlist state of a consultancy
  const toggleSortlist = (event, consultancy) => {
    event.stopPropagation();
    const isAlreadySortlisted = sortlistedConsultancyIds.includes(
      consultancy._id
    );
    // Add or remove consultancy ID from the shortlisted state
    const updatedSortlist = isAlreadySortlisted
      ? sortlistedConsultancyIds.filter((id) => id !== consultancy._id)
      : [...sortlistedConsultancyIds, consultancy._id];
    setSortlistedConsultancyIds(updatedSortlist);
    console.log("Sortlisted Ids", sortlistedConsultancyIds);
    setShowSortlistPopup(true);
  };

  // for list consultancy button
  const [isPopOpen, setIsPopOpen] = useState(false);
  const openPopup = () => {
    setIsPopOpen(true);
    document.body.style.overflow = "hidden";
  };
  const closePopup = () => {
    setIsPopOpen(false);
    document.body.style.overflow = "";
  };

  // Function to check if a consultancy is shortlisted
  const isSortlisted = (consultancyId) => {
    return sortlistedConsultancyIds.includes(consultancyId);
  };

  // onselectconsultancy function here
  const handleSelectConsultancy = async (
    consultancyId = "672f396dd165c7fcb0f96528"
  ) => {
    try {
      setLoadingConsultancy(true);
      const response = await getDataById(
        "user/findconsultancybyid",
        consultancyId
      );
      if (response.data) {
        setSelectedConsultancy(response.data);
      } else {
        toast.error("Server Error, Please Contact Administrator.");
        return [];
      }
    } catch (err) {
      toast.error("Error Fetching Data");
    } finally {
      setLoadingConsultancy(false);
    }
  };

  return (
    <div
      className={`browse-container ${
        currentPage === "browse" ? "active" : "hidden"
      }`}
    >
      {/* Left column with consultancy cards */}
      <div className="left-column">
        {consultancyOptions.length < 1 ? (
          <>
            <SkeletonConsultancyCard />
            <SkeletonConsultancyCard />
            <SkeletonConsultancyCard />
            <SkeletonConsultancyCard />
          </>
        ) : (
          consultancyOptions.map((consultancy) => (
            <ConsultancyCard
              key={consultancy._id}
              consultancy={consultancy}
              onSelect={() => handleSelectConsultancy(consultancy._id)}
              onSortlist={(event) => toggleSortlist(event, consultancy)}
              isSortlisted={isSortlisted(consultancy._id)}
            />
          ))
        )}
      </div>
      {/* Right column with consultancy details */}
      <div
        className={`${
          loadingConsultancy || selectedConsultancy ? "active" : ""
        } right-column`}
      >
        {loadingConsultancy ? (
          <SkeletonConsultancyDetail />
        ) : selectedConsultancy ? (
          <ConsultancyDetail
            consultancy={selectedConsultancy}
            onSortlist={(event) => toggleSortlist(event, selectedConsultancy)}
            isSortlisted={isSortlisted(selectedConsultancy._id)}
            closeTab={() => setSelectedConsultancy(null)}
          />
        ) : (
          <SkeletonConsultancyDetail />
        )}
      </div>
      {/* Sortlist popup */}
      <Sortlist
        consultancies={consultancyOptions.filter((c) =>
          sortlistedConsultancyIds.includes(c._id)
        )} // Only show shortlisted consultancies
        show={showSortlistPopup}
        toggleSortlist={toggleSortlist}
        onClear={() => setSortlistedConsultancyIds([])}
        onSubmit={openPopup}
      />

      <SubmitForm isPopOpen={isPopOpen} closePopup={closePopup} />
    </div>
  );
}
