import React, { useContext, useEffect, useState } from "react";
import "./Filter.scss";
import target from "../../assets/favicons/target.png";

import cityImg from "../../assets/favicons/city.svg";
import uni from "../../assets/favicons/uni.svg";
import courseImg from "../../assets/favicons/course.svg";
import countryImg from "../../assets/favicons/country.svg";
import sliders from "../../assets/favicons/Sliders.svg";

import nextEdu from "../../assets/images/next-edu.png";
import aecc from "../../assets/images/aecc.png";
import kangaroo from "../../assets/images/kangaroo.png";
import Dropdown from "../dropdown/Dropdown";
import { FaArrowRight } from "react-icons/fa";

import DistrictDropdown from "../districtDropdown/DistrictDropdown.jsx";
import { PageContext } from "../../contexts/PageContext.js";

export default function Filter() {
  const {
    currentPage,
    country,
    city,
    university,
    course,
    district,
    handleFilterButton,

    isDropdownVisible,
    setIsDropdownVisible,
  } = useContext(PageContext);

  // for Dropdown States
  const [selectedOptions, setSelectedOptions] = useState({
    selectedCountries: [],
    selectedCities: [],
    selectedUniversities: [],
    selectedCourses: [],
  });
  const [selectedDistricts, setSelectedDistricts] = useState([]);

  const handleOptionSelect = (optionValue, isChecked, dropdownName) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [dropdownName]: isChecked
        ? [...prevOptions[dropdownName], optionValue]
        : prevOptions[dropdownName].filter((option) => option !== optionValue),
    }));
  };

  const DropdownData = [
    {
      img: countryImg,
      name: "Countries",
      options: country,
      type: "selectedCountries",
    },
    {
      img: cityImg,
      name: "Cities",
      options: city,
      type: "selectedCities",
    },
    {
      img: uni,
      name: "Universities",
      options: university,
      type: "selectedUniversities",
    },
    {
      img: courseImg,
      name: "Courses",
      options: course,
      type: "selectedCourses",
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200) {
        setIsDropdownVisible(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSearchClick = (selectedOptions, selectedDistricts) => {
    handleFilterButton(selectedOptions, selectedDistricts);
    if (window.innerWidth < 1200) {
      setIsDropdownVisible(false);
    }
  };

  return (
    <div
      className={`filter ${currentPage === "browse" ? "browse" : "howitworks"}`}
    >
      <div className="title">
        <div className="text-1">
          <img src={target} alt="target-icon" />
          FIND CONSULTANCY BY
        </div>

        <DistrictDropdown
          options={district}
          selectedDistrict={selectedDistricts}
          setSelectedDistrict={setSelectedDistricts}
          className="district-dropdown-1"
        />
      </div>

      <div className="dropdown-box">
        <div className="dropdown-options">
          {/* District Dropdown For Smaller Browse Page */}
          <div
            className={`sm-drop ${
              currentPage == "browse" ? "browse" : "howitworks"
            }`}
          >
            <DistrictDropdown
              smallScreen={true}
              options={district}
              selectedDistrict={selectedDistricts}
              setSelectedDistrict={setSelectedDistricts}
              className="district-dropdown-1"
            />

            <button
              onClick={() => setIsDropdownVisible(!isDropdownVisible)}
              className="slider-btn"
            >
              <div>
                <img src={sliders} alt="filter icon" />
              </div>
              <div>{isDropdownVisible ? "Less Filter" : "More Filter"}</div>
            </button>
          </div>

          {/* dropdown  */}
          {isDropdownVisible && (
            <>
              {DropdownData.map((dropdown) => (
                <Dropdown
                  key={dropdown.type}
                  icon={dropdown.img}
                  name={dropdown.name}
                  options={dropdown.options}
                  selectedOptions={selectedOptions[dropdown.type]}
                  onOptionSelect={(optionValue, isChecked) =>
                    handleOptionSelect(optionValue, isChecked, dropdown.type)
                  }
                />
              ))}

              {/* search btn */}
              <div
                className="filter-btn"
                onClick={() => {
                  handleSearchClick(selectedOptions, selectedDistricts);
                }}
              >
                <div className="btn-text">Search</div>
                <div className="btn-icon">
                  <FaArrowRight />
                </div>
              </div>
            </>
          )}
        </div>

        {/* popular options */}
        <div className="popular-options">
          <div className="title">Popular Consultancies</div>
          <div className="options">
            <div className="option">
              <img src={nextEdu} alt="next education" />
              <p>The Next Education Consultancy Pvt. Ltd.</p>
            </div>
            <div className="option">
              <img src={aecc} alt="aecc" />
              <p>AECC-Education Consultants</p>
            </div>
            <div className="option">
              <img src={kangaroo} alt="kangaroo" />
              <p>Kangaroo Education Foundation</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
