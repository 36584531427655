import React, { useEffect, useState } from "react";
import "./ConsultancyDetail.scss";
// import success from "../../assets/favicons/success.svg";
import verified from "../../assets/favicons/verified.svg";
import sortlistFill from "../../assets/favicons/sortlist-fill.png";
import sortlist from "../../assets/favicons/sortlist.png";
// import visa from "../../assets/favicons/visa-applied.svg";
import pan from "../../assets/favicons/pan.svg";
import { IoClose, IoChevronBack, IoChevronForward } from "react-icons/io5";

export default function ConsultancyDetail({
  consultancy,
  onSortlist,
  isSortlisted,
  closeTab,
}) {
  const [showMore, setShowMore] = useState(false);
  const [visibleCountries, setVisibleCountries] = useState(0);
  const countriesToShow = 4;

  const handleScroll = (direction) => {
    setVisibleCountries((prev) =>
      direction === "left"
        ? Math.max(prev - countriesToShow, 0)
        : Math.min(
            prev + countriesToShow,
            consultancy.countries.length - countriesToShow
          )
    );
  };

  return (
    <div className="consultancy-detail">
      <div className="close-box">
        <button onClick={closeTab}>
          <IoClose />
        </button>
      </div>

      <div className="header">
        <div className="title">{consultancy.name}</div>
        <div className={`sortlist-btn ${isSortlisted ? "isSortlisted" : ""}`}>
          <button onClick={onSortlist}>
            {isSortlisted ? (
              <img src={sortlistFill} alt="sortlist fill" />
            ) : (
              <img src={sortlist} alt="sortlist" />
            )}
            <div className="sort-btn-text">Sortlist</div>
          </button>
        </div>
      </div>

      {/* Statistics section */}
      <div className="statistic">
        <div className="box verified">
          <div className="box-2">
            <img src={verified} alt="verified" />
            <div className="content">
              <div className="text-1">
                {consultancy.verified ? "Verified" : "Pending"}
              </div>
              <div className="text-2">ECAN</div>
            </div>
          </div>
        </div>

        <div className="box pan">
          <div className="box-2">
            <img src={pan} alt="pan" />
            <div className="content">
              <div className="text-1">{consultancy.pan.panNumber}</div>
              <div className="text-2">PAN</div>
            </div>
          </div>
        </div>
      </div>

      {/* Description section with expandable text */}
      <div className="description">
        {showMore
          ? consultancy.description
          : `${consultancy.description.slice(0, 500)}...`}
        {consultancy.description.length > 500 && (
          <button onClick={() => setShowMore(!showMore)}>
            {showMore ? "Show Less" : "Show More"}
          </button>
        )}
      </div>

      {/* Destination section with scrollable country list */}
      <div className="destination">
        <div className="title">
          Destination
          <div className="arrows">
            <button onClick={() => handleScroll("left")}>
              <IoChevronBack />
            </button>
            <button onClick={() => handleScroll("right")}>
              <IoChevronForward />
            </button>
          </div>
        </div>
        <div className="country-list">
          {consultancy.countries
            .slice(visibleCountries, visibleCountries + countriesToShow)
            .map((item) => (
              <div key={item._id} className="country">
                {item.image ? (
                  <img
                    src={item.image}
                    alt={item.name}
                    className="country-image"
                  />
                ) : (
                  <div className="country-placeholder" />
                )}
                <span className="country-name">{item.name}</span>
              </div>
            ))}
          {/* If there are fewer countries than the total visible cards */}
          {Array.from({
            length:
              countriesToShow -
              consultancy.countries.slice(
                visibleCountries,
                visibleCountries + countriesToShow
              ).length,
          }).map((_, idx) => (
            <div key={idx} className="country empty-country-card">
              <div className="country-placeholder" />
            </div>
          ))}
        </div>
      </div>

      {/* University section with pills */}
      <div className="university">
        <div className="title">
          University
          {consultancy.universities.length > 4 && (
            <button onClick={() => setShowMore(!showMore)}>
              {showMore ? "Show Less" : "Show More"}
            </button>
          )}
        </div>
        <div className="university-list">
          {(showMore
            ? consultancy.universities
            : consultancy.universities.slice(0, 4)
          ).map((item) => (
            <div key={item._id} className="pill">
              {item.name}
            </div>
          ))}
        </div>
      </div>

      {/* Course section with full-width pills */}
      <div className="course">
        <div className="title">Course</div>
        <div className="course-list">
          {consultancy.courses.map((item) => (
            <>
              <div key={item._id} className="course-pill">
                {item.name}

                <div className="course-point">
                  <ul>
                    <li>Points</li>
                    <li>Points</li>
                    <li>Points</li>
                  </ul>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
