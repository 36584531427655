import React, { useEffect, useRef, useState } from "react";
import "./Dropdown.scss";
import { TiArrowSortedDown } from "react-icons/ti";

export default function Dropdown({
  icon,
  name,
  options,
  onOptionSelect,
  selectedOptions,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    // Close dropdown if clicked outside
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOptionChange = (event) => {
    const optionValue = event.target.value;
    const isChecked = event.target.checked;
    onOptionSelect(optionValue, isChecked);
  };

  return (
    <div ref={divRef} className={`dropdown-container ${isOpen ? "open" : ""}`}>
      <div
        className="dropdown-header"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="dropdown-title">
          <div className="icon">
            <img src={icon} alt={name} />
          </div>
          {name}
          {selectedOptions.length < 1 ? (
            ""
          ) : (
            <span className="count-pill">{selectedOptions.length}</span>
          )}
        </div>
        <div className="toggle-arrow">
          <div className={`arrow-wrapper ${isOpen ? "rotate" : ""}`}>
            <TiArrowSortedDown />
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="dropdown-content" onMouseLeave={() => setIsOpen(false)}>
          {/* Input container */}
          <div className="input-container">
            <div className="selected-options">
              <input
                type="text"
                name="search-input"
                id="search-input"
                className="search-input"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          {/* Options container */}
          <div className="options-container">
            {options.length < 1 ? (
              <div style={{ padding: ".5rem" }}>loading...</div>
            ) : (
              options
                .filter((option) =>
                  option.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((option) => (
                  <div
                    key={option._id}
                    className="option"
                    onClick={() =>
                      handleOptionChange({
                        target: {
                          value: option._id,
                          checked: !selectedOptions.includes(option._id),
                        },
                      })
                    }
                  >
                    <input
                      type="checkbox"
                      value={option._id}
                      checked={selectedOptions.includes(option._id)}
                      onChange={handleOptionChange}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <span className="option-name">{option.name}</span>
                  </div>
                ))
            )}
          </div>
        </div>
      )}
    </div>
  );
}
