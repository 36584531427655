import { axiosInstance } from "./axiosInstance";

// get by id
export const getDataById = async (endpoint, id) => {
  const bodyData = { id: id };
  const response = await axiosInstance
    .post(endpoint, bodyData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("Error Getting Data", error);
      return error;
    });
  return response;
};

// get
export const getAllData = async (endpoint) => {
  const result = await axiosInstance
    .get(endpoint)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return result;
};

// post
export const storeData = async (endpoint, data) => {
  const result = await axiosInstance
    .post(endpoint, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

  return result;
};

// delete
export const deleteById = async (endpoint) => {
  const result = await axiosInstance
    .delete(endpoint)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return result;
};

// update
export const updateData = async (endpoint, updatedData) => {
  const result = await axiosInstance
    .patch(endpoint, updatedData)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
  return result;
};
