import React, { useState, useEffect, createContext, useContext } from "react";
import Navbar from "../../section/Navbar/Navbar";
import Hero from "../../section/Hero/Hero";
import Browse from "../../section/Browse/Browse";
import HowItWorks from "../../section/HowItWorks/HowItWorks";
import Footer from "../../section/Footer/Footer";
import "./HomeLayout.scss";
import Loader from "../../components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PageContext } from "../../contexts/PageContext";

export default function HomePageLayout() {
  const { loading, currentPage } = useContext(PageContext);

  return (
    <>
      <div className="home-layout">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Navbar />
            <div
              className={`main ${
                currentPage === "browse" ? "browse" : "howitworks"
              }`}
            >
              <Hero />
              <Browse />
              <HowItWorks />
              <Footer />
            </div>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </>
        )}
      </div>
    </>
  );
}
