import React, { useState, useEffect, useContext } from "react";
import "./Navbar.scss";
import { FaArrowRight } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "../../assets/favicons/favicon.svg";
import Popup from "../../components/popup/Popup";
import { PiBookmarkFill } from "react-icons/pi";
import { storeData } from "../../api/apiService";
import IconCall from "../../utils/iconCall/IconCall";
import benefits from "../../assets/images/benefits.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { PageContext } from "../../contexts/PageContext";

export default function Navbar() {
  const history = useHistory();
  const { currentPage, setCurrentPage, setIsDropdownVisible } =
    useContext(PageContext);

  // data
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [website, setWebsite] = useState("");
  const [error, setError] = useState({});

  // submission message
  const [submissionMessage, setSubmissionMessage] = useState(""); // for success or failure message
  const [messageType, setMessageType] = useState(""); // to handle CSS for success or error

  // Image popup
  const [imgPop, setImgPop] = useState(false);
  const closeImgPop = () => {
    setImgPop(false);
  };

  // for sidebar onClick Hamburger
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const toggleOverlay = () => {
    setIsOverlayOpen(!isOverlayOpen);
  };

  // Close the overlay when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const overlay = document.querySelector(".toggle-btns");
      const hamIcon = document.querySelector(".ham-icon");
      if (
        isOverlayOpen &&
        !overlay.contains(event.target) &&
        !hamIcon.contains(event.target)
      ) {
        setIsOverlayOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOverlayOpen]);

  // for list consultancy button
  const [isPopOpen, setIsPopOpen] = useState(false);
  const openPopup = () => {
    setIsPopOpen(true);
    document.body.style.overflow = "hidden";
  };
  const closePopup = () => {
    setIsPopOpen(false);
    document.body.style.overflow = "";
  };

  // Form validation
  const validate = () => {
    let tempErrors = {};

    // Validate consultancy name
    if (!name) {
      tempErrors.name = "Consultancy name is required";
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      tempErrors.email = "Email is required";
    } else if (!emailRegex.test(email)) {
      tempErrors.email = "Please enter a valid email";
    }

    // Validate phone number
    const phoneRegex = /^(\+977)?\d{10}$/;
    if (!phoneNo) {
      tempErrors.phoneNo = "Phone number is required";
    } else if (!phoneRegex.test(phoneNo)) {
      tempErrors.phoneNo = "Please enter a valid number";
    }

    // Validate website (optional)
    if (website) {
      const websiteRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}\/?$/;
      if (!websiteRegex.test(website)) {
        tempErrors.website = "Please enter a valid website URL";
      }
    }

    setError(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // handle submit list consultancy buttons
  const handleSubmitListConsultancy = () => {
    if (validate()) {
      const newConsultancy = {
        consultancyName: name,
        phoneNumber: phoneNo,
        email: email,
        website: website,
      };
      storeData("consultancy/addconsultancydata", newConsultancy)
        .then((response) => {
          toast.success("Your request has been submitted.");
          closePopup();

          // Clear the form
          setName("");
          setEmail("");
          setPhoneNo("");
          setWebsite("");

          setTimeout(() => {
            setSubmissionMessage("");
            setMessageType("");
          }, 2000);
        })
        .catch((error) => {
          toast.error(
            "There was an error submitting your request. Please try again."
          );
        });
    } else {
      toast.error("Validation Errors.");
    }
  };

  const handleLogoClick = () => {
    setCurrentPage("howitworks");
    setIsDropdownVisible(true);
  };

  return (
    <div className="navbar">
      {/* popup for list consultancy */}
      <Popup isOpen={isPopOpen} onClose={closePopup}>
        <div className="list-consultancy">
          <div className="title-section">
            <div className="title-1">
              <div className="save-icon">
                <PiBookmarkFill />
              </div>
              <div className="title-text">Submit Request</div>
            </div>
            <div className="description">
              Fill the form and submit to get your consultancies onboard.
            </div>
          </div>

          {/* form box */}
          <form>
            <div className="input-box">
              <label>
                Consultancy Name <span>*</span>
                <span className="error-msg">
                  {error.name ? error.name : ""}
                </span>
              </label>
              <input
                type="text"
                placeholder="Your Consultancy Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="input-box">
              <label>
                Email <span>*</span>
                <span className="error-msg">
                  {error.email ? error.email : ""}
                </span>
              </label>
              <input
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="input-box">
              <label>
                Phone No. <span>*</span>
                <span className="error-msg">
                  {error.phoneNo ? error.phoneNo : ""}
                </span>
              </label>
              <input
                type="text"
                placeholder="+977 98XXXXXXXX"
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                required
              />
            </div>

            <div className="input-box">
              <label>
                Website <span>(optional)</span>
                <span className="error-msg">
                  {error.website ? error.website : ""}
                </span>{" "}
              </label>
              <input
                type="text"
                placeholder="Your Website Link"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>

            <div className="terms-box">
              Benefits for your consultancy.{" "}
              <span onClick={() => setImgPop(true)}>Click here.</span>
            </div>

            <div className="button">
              <div className="cancel-btn" onClick={closePopup}>
                Cancel
              </div>
              <div className="submit-btn" onClick={handleSubmitListConsultancy}>
                Submit
              </div>
            </div>
          </form>
        </div>
      </Popup>

      {/* popup for image */}
      {imgPop && (
        <div className="img-overlay">
          <div className="img-content">
            <span onClick={closeImgPop} className="img-close-pop">
              {/* <IoCloseSharp /> */}
              close
            </span>
            <img
              src={benefits}
              alt="benefits of many consultancy"
              className="benefit-img"
            />
          </div>
        </div>
      )}

      {/* main navbar */}
      <div className="link">
        <a
          href="#"
          onClick={() => setCurrentPage("browse")}
          className={`${currentPage === "browse" ? "active" : ""}`}
        >
          Browse
        </a>
        <a
          href="#howitworks"
          onClick={() => setCurrentPage("howitworks")}
          className={`${currentPage === "howitworks" ? "active" : ""}`}
        >
          How It Works
        </a>
      </div>
      <div className="logo">
        <button className="logo-btn" onClick={handleLogoClick}>
          <img src={logo} alt="Many Consultancy" />
        </button>
      </div>

      {/* action buttons */}
      <div className="action-buttons">
        <div className="ham-icon" onClick={toggleOverlay}>
          <GiHamburgerMenu />
        </div>
        <div className={`toggle-btns ${isOverlayOpen ? "open" : ""}`}>
          <div className="btn-call-us">
            <div className="btn-icon">
              <IconCall />
            </div>
            <div className="text">
              <div className="title-2">
                <a href="tel:+9779849037462">+977 9849037462</a>
              </div>
            </div>
          </div>
          <div className="btn-list-consultancy" onClick={openPopup}>
            <div className="btn-text">List Consultancy</div>
            <div className="btn-icon">
              <FaArrowRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
